import {useSelector} from "react-redux";
import Modal from "react-bootstrap/Modal";
import {useEffect, useState} from "react";
import AvailabilityCalender from "./availability-calender";
import "./availability.css"

const Availability = ({lodgingId, lodgingName, lodgingType, showAvailability, closeAvailabilityModal}) => {
  const reservations = useSelector(state => state.reservations.reservations);
  const [reservedDates, setReservedDates] = useState([]);

  useEffect(() => {
    const selectLodgingReservations = reservations.filter(res => res.lodgingId === lodgingId);
    setReservedDates(getAllReservedDates(selectLodgingReservations));
  }, [lodgingId]);

  const getAllReservedDates = (reservations) => {
    console.log(lodgingType);
    if(lodgingType === 'CAMPGROUND') return [];
    return reservations.reduce((accumulator, reservation) => {
      const startDate = new Date(reservation.startDate);
      const endDate = new Date(reservation.endDate);
      if(startDate > endDate) return accumulator;
      const newAccumulator = accumulator;
      let i = new Date(startDate);
      while(i < endDate) {
        newAccumulator.push(i);
        i = new Date(i.getTime()+1000*60*60*24);
      }
      return newAccumulator;
    }, []);
  }

  return (
    <Modal show={showAvailability} onHide={closeAvailabilityModal} size={'lg'}>
      <Modal.Header closeButton>
        <Modal.Title>{lodgingName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AvailabilityCalender reservedDates={reservedDates}/>
        <div id='legend'>
          <div><span></span><p>White dates are available dates</p></div>
          <div><span id='legend-unavailable'></span><p>Gray dates are unavailable dates</p></div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default Availability;