import Body from "../body";
import Spinner from "react-bootstrap/Spinner";
import {useEffect, useState} from "react";
import axios from "axios";
import {useDispatch} from "react-redux";
import {actions} from "../../actions";
import data from "bootstrap/js/src/dom/data";


const Main = () => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    loadInitialData();
  }, []);

  const loadInitialData = async () => {
    try {
      const initialData = (await axios.get(`${process.env.REACT_APP_API_URI}/client/initial-data/1`)).data.data.initialData;
      dispatch({type: actions.get_business, payload: initialData.business});
      dispatch({type: actions.get_announcements, payload: initialData.announcements});
      dispatch({type: actions.get_lodging, payload: initialData.lodgings});
      dispatch({type: actions.get_reservations, payload: initialData.reservations});
      setIsLoading(false);
    } catch (e) {console.log('Failure to load initial data. Contact Admin.')}
  }

  return (
    <>
      {!isLoading && <Body></Body>}
      {isLoading && <Spinner></Spinner>}
    </>
  );
}

export default Main;