import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {useState} from "react";
import "../modal.css"

const PhotoGallery = ({lodgingName, lodgingImages, showImages, closePhotoGalleryModal}) => {
  const [imageToDisplay, setImageToDisplay] = useState(0);

  const resetImageToDisplayAndCloseModal = () => {
    setImageToDisplay(0);
    closePhotoGalleryModal();
  }

  const next = () => {
    const nextInArray = imageToDisplay === lodgingImages.length - 1 ? 0 : imageToDisplay + 1;
    setImageToDisplay(nextInArray);
  }

  const previous = () => {
    const previousInArray = imageToDisplay === 0 ? lodgingImages.length - 1 : imageToDisplay - 1;
    setImageToDisplay(previousInArray);
  }

  return (
    <Modal show={showImages} onHide={resetImageToDisplayAndCloseModal} size={'lg'}>
      <Modal.Header closeButton>
        <Modal.Title>{lodgingName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          {lodgingImages.length > 1 &&
            <>
              <Button onClick={previous}>Previous</Button>
              <Button onClick={next}>Next</Button>
            </>
          }
          <Button onClick={resetImageToDisplayAndCloseModal}>Close</Button>
          <p>{imageToDisplay + 1} / {lodgingImages.length}</p>
        </div>
        <img src={lodgingImages[imageToDisplay]}/>
      </Modal.Body>
    </Modal>
  )
}

export default PhotoGallery;