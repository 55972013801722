import Container from 'react-bootstrap/Container';
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import './CustomNav.css'
import {Outlet, NavLink} from "react-router-dom";
import {NavbarBrand} from "react-bootstrap";
import facebookIcon from './../../static-imgs/facebook.png';
import {useSelector} from "react-redux";

const CustomNav = () => {
  const businessInfo = useSelector(state => {
    return state.business.businessDetail
  });

  return (
    <>
      <Navbar expand="lg" id="main-nav" bg="dark" data-bs-theme="dark">
        <Container >
          <NavbarBrand>RABER BAY LANDING</NavbarBrand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav navbarScroll >
              <NavLink to="/" className={({ isActive }) =>
                (isActive ? "custom-active nav-link" : "nav-link")}>Home</NavLink>
              <NavLink to="/lodging" className={({ isActive }) =>
                (isActive ? "custom-active nav-link" : "nav-link")}>Lodging</NavLink>
              <NavLink to="/reservations" className={({ isActive }) =>
                (isActive ? "custom-active nav-link" : "nav-link")}>Reservations</NavLink>
              <NavLink to="/policies" className={({ isActive }) =>
                (isActive ? "custom-active nav-link" : "nav-link")}>Campground Rules</NavLink>
            </Nav>
          </Navbar.Collapse>
        </Container>
        <Container id='facebookIcon'>
          <a href={'https://' + businessInfo.facebookUrl} target='_target'><img src={facebookIcon} alt='Facebook' /></a>
        </Container>
      </Navbar>
      <Outlet/>
    </>
  );
}
export default CustomNav;