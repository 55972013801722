import {useEffect, useState} from "react";
import calenderUtil from "./calender-util";
import './availability-calender.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretLeft, faCaretRight} from "@fortawesome/free-solid-svg-icons";

const AvailabilityCalender = ({reservedDates}) => {
  const [month, setMonth] = useState(5);
  const [disabledDates, setDisabledDates] = useState([]);
  const [monthDates, setMonthDates] = useState([]);
  const weekDayDisplay = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

  useEffect(() => {
    setDisabledDates(filterMonthsToDisable(month));
    setMonthDates(calenderUtil.getDaysInMonth(month - 1));
  }, [month, reservedDates]);

  const incrementMonth = () => {
    if(month < 9) {
      const newMonth = month + 1;
      setMonth(newMonth);
    }
  }

  const decrementMonth = () => {
    if(month > 5) {
      const newMonth = month - 1;
      setMonth(newMonth);
    }
  }

  const filterMonthsToDisable = (monthNumber) => {
    return reservedDates.filter(date => date.getMonth() + 1 === monthNumber);
  }

  const getClassName = (date) => {
    if(!date) return 'date-div padding';
    return isDisabledDate(date) ? 'disabled date-div' : 'date-div';
  }

  const getKey = (date, index) => {
    if(!date) return `padding-${index}`;
    return `${date.getFullYear()}${date.getMonth()}${date.getDate()}`
  }

  const isDisabledDate = (date) => {
    return disabledDates.some(disabledDate => {
      if (!disabledDate || !date) return false;
      const a = new Date(date);
      const b = new Date(disabledDate);
      return (a?.getFullYear() === b?.getFullYear()
        && a?.getMonth() === b?.getMonth()
        && a?.getDate() === b?.getDate())
    })
  }

  return (
    <div>
      <div id='top'>
        <div id='month'><h1>{`${calenderUtil.getMonthDisplay(month)} ${monthDates[10]?.getFullYear()}`}</h1></div>
        <div id='increment-decrement'>
          <FontAwesomeIcon icon={faCaretLeft} onClick={decrementMonth} className='next-previous'/>
          <FontAwesomeIcon icon={faCaretRight} onClick={incrementMonth} className='next-previous'/>
        </div>
      </div>
      <div className='calender-wrapper'>
        {
          weekDayDisplay.map((display, index) => (
            <div className='date-div padding' key={`weekday-${index}`}>{display}</div>
          ))
        }
        {
          monthDates.map((date, i) => (
            <div
              className={getClassName(date)}
              key={getKey(date, i)}
            >{date?.getDate() ?? ''}</div>
          ))
        }
      </div>
    </div>
  )
}

export default AvailabilityCalender;