import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Main from './frame/main';
import {Provider} from "react-redux";
import store from "./store";

function App() {
  return (
    <Provider store={store}>
      <Main></Main>
    </Provider>
  );
}

export default App;
