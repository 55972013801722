import {Accordion} from "react-bootstrap";

const OtherHelpfulInfo = ({eventKey, streetAddress, city, zipCode}) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>Other Helpful Info</Accordion.Header>
      <Accordion.Body>
        <p>We are located at {streetAddress}, {city}, MI {zipCode}.</p>

        <p>A $50 deposit is required to reserve your spot at the campground. You may cancel your reservation without being charged for the reservation, but
          the deposit will not be refunded.
        </p>
        <p>Pets are allowed, but there is a $50 pet cleaning fee.</p>
        <p>We kindly ask that you do not smoke inside any of the cabins or the apartment.</p>
        <p>Please read our policies or give a us a call for any further questions.</p>
      </Accordion.Body>
    </Accordion.Item>
  );
}

export default OtherHelpfulInfo;