import "./footer.css"
import {useSelector} from "react-redux";
import {getPhoneNumberDisplay} from "../../util";

function Footer() {
  const businessInfo = useSelector(state => {
    return state.business.businessDetail
  });

  return (
    <footer>
      <div>
        <h3>RABER BAY LANDING</h3>
      </div>
      <div>
        <h3>CONTACT US</h3>
        <ul>
          <li><a href={'https://' + businessInfo.facebookUrl} target='_blank'>Message us on Facebook</a></li>
          <li>{getPhoneNumberDisplay(businessInfo.phoneNumber)}</li>
        </ul>
      </div>
      <div>
        <h3>LOCATION</h3>
        <ul>
          <li>{businessInfo.businessName}</li>
          <li>{businessInfo.streetAddress}</li>
          <li>{`${businessInfo.city}, MI ${businessInfo.zipCode}`}</li>
        </ul>
      </div>
    </footer>
  )
}

export default Footer;