import {actions} from "../actions";

const initial = {
  loading: true,
  reservations: [],

}

const reducer = (state=initial, action) => {
  switch (action.type) {
    case actions.get_reservations:
      state = {reservations: action.payload, loading: false};
      return state;
    default: return state;
  }
}

export default reducer;