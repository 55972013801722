import {useSelector} from "react-redux";
import './reservations.css';
import ReservationDirections from "./ReservationDirections";
import {Accordion} from "react-bootstrap";
import SettingUpReservation from "./SettingUpReservation";
import OtherHelpfulInfo from "./OtherHelpfulInfo";

const Reservations = () => {
  const business = useSelector(state => state.business.businessDetail)

  return (
    <div id='reservations-info'>
      <Accordion defaultActiveKey={[0, 1]} alwaysOpen={true}>
        <SettingUpReservation eventKey={0} phoneNumber={business.phoneNumber} />
        <ReservationDirections eventKey={1}/>
        <OtherHelpfulInfo eventKey={2} zipCode={business.zipCode} city={business.city}
                          streetAddress={business.streetAddress}></OtherHelpfulInfo>
      </Accordion>



    </div>
  )
}

export default Reservations;