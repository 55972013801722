import Announcements from "./announcements";
import InfoCard from "./info-card";
import fishingPic from './../../../static-imgs/fishing-bubble-pic.jpg';
import campingPic from './../../../static-imgs/camping-bubble-pic.jpg';
import hikingPic from './../../../static-imgs/hiking-bubble-pic.jpg';
import sightSeeingPic from './../../../static-imgs/sightseeing-bubble-pic.jpg';

const home = () => {

  const path = './../../static-imgs/fishin'

  return (
    <>
      <div id="header-div"></div>
      <Announcements/>
      <div id="info-card-div">
        <InfoCard cardTitle={"Fishing"} cardMessage={"The St. Mary's river is an excellent fishing location."} cardImage={fishingPic}/>
        <InfoCard cardTitle={"Camping"} cardMessage={"We have camper and tent spots along with cabins for lodging."} cardImage={campingPic} />
        <InfoCard cardTitle={"Hiking"} cardMessage={"Visit the many trails in beautiful Upper Peninsula Michigan."} cardImage={hikingPic} />
        <InfoCard cardTitle={"Sightseeing"} cardMessage={"Visit historic lighthouses around Lake Superior."} cardImage={sightSeeingPic} />
      </div>
    </>
  )
}

export default home;