import {useSelector} from "react-redux";
import {Carousel} from "react-bootstrap";
import './home.css'

const Announcements = () => {
  const announcements = useSelector(state => state.business.announcements);

  return (
    <Carousel>
      {announcements?.map(announcement => (
        <Carousel.Item key={announcement.id}>
          <img src={announcement.image}/>
          <Carousel.Caption>
            <h3>{announcement.title}</h3>
            <p>{announcement.message || ''}</p>
          </Carousel.Caption>
        </Carousel.Item>
        ))
      }
    </Carousel>
  )
}
export default Announcements;