import {Accordion} from "react-bootstrap";
import {getPhoneNumberDisplay} from "../../../util";

const SettingUpReservation = ({eventKey, phoneNumber}) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>Setting Up a Reservation</Accordion.Header>
      <Accordion.Body>
        <p>Thank you for your interest in staying at Raber Bay Landing 🎉 Please read the following for setting up a reservation and important information
          about your stay.</p>
        <p>Availability can be checked on the lodging page or by calling.
          To reserve lodging or check availability at the campground please call {' ' + getPhoneNumberDisplay(phoneNumber)}.
          Please be aware that the campground is only open from May 1st to September 30th.</p>
      </Accordion.Body>
    </Accordion.Item>
  );
}

export default SettingUpReservation;