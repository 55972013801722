import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import './info-card.css'

function infoCard({cardTitle, cardImage, cardMessage}) {
  return (
    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>{cardTitle}</Card.Title>
        <Card.Text>{cardMessage}</Card.Text>
      </Card.Body>
      <Card.Img variant="bottom" src={cardImage} alt={cardTitle}/>
    </Card>
  );
}

export default infoCard;