const getYear = () => {
  const today = new Date(Date.now());
  const year = today.getFullYear();
  return today.getMonth() > 9 ? year + 1 : year;
}

const getLastDayOfMonth = (monthNumber) => {
  return new Date(getYear(), monthNumber + 1, 0);
}

const getDaysInMonth = (monthNumber) => {
  const endDate = getLastDayOfMonth(monthNumber);
  const allDays = [];
  for(let i = 1; i <= endDate.getDate(); i++) {
    allDays.push(new Date(endDate.getFullYear(), monthNumber, i));
  }
  return padMonth(allDays);
}

const padMonth = (allDays) => {
  let startDate = allDays[0].getDay();
  while(startDate >= 1) {
    allDays.unshift(null);
    startDate--;
  }
  return allDays;
}

const getMonthDisplay = (monthNumber) => {
  switch (monthNumber) {
    case 5: return 'May';
    case 6: return 'June';
    case 7: return 'July';
    case 8: return 'August';
    case 9: return 'September';
    default: return 'Month';
  }
}

export default {
  getDaysInMonth,
  getMonthDisplay};