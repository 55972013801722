import CampgroundRules from "./CampgroundRules";

const Policies = () => {
  return (
    <>
      <CampgroundRules/>
    </>
  )
}

export default Policies;