import {actions} from "../actions";

const initial = {
  businessDetailsLoading: true,
  businessDetail: {},
  announcementsLoading: true,
  announcements: [],
}

const reducer = (state=initial, action) => {
  switch(action.type) {
    case actions.get_business:
      state = {...state, businessDetail: action.payload, businessDetailsLoading: false};
      return state;
    case actions.get_announcements:
      state = {...state, announcements: action.payload, announcementsLoading: false};
      return state;
    default: return state;
  }
}
export default reducer;