import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import './lodging.css'
import {forEach} from "react-bootstrap/ElementChildren";

const LodgingFullDetails = ({lodging, openPhotoGalleryModal, openAvailabilityModal}) => {

  const getFullBeddingDisplayString = (bedding) => {
    const keys = Object.keys(bedding);
    let string = '';
    keys.forEach(key => string = string + getSingleBeddingString(bedding, key));
    return string.slice(0, -2);
  }

  const getSingleBeddingString = (bedding, key) => {
    if (bedding[key] === 0) return '';
    const number = bedding[key];
    switch (key) {
      case 'queen': return `${number} Queen, `;
      case 'fullBed': return `${number} Full Bed, `;
      case 'twin': return `${number} Twin, `;
      case 'futon': return `${number} Futon, `;
      case 'couch': return `${number} Couch, `;
      default: return '';
    }
  }

  return (
    <>
      <Card>
        <Card.Img variant="top" src={lodging.images[0]}/>
        <Card.Body>
          <Card.Title>{lodging.name}</Card.Title>
          <h3>Rates</h3>
          <Card.Text>${lodging.weeklyRate} per week or ${lodging.nightlyRate} per night</Card.Text>
          {
            lodging.lodgingType !== 'CAMPGROUND' ?
              <>
                <Card.Text>{lodging.rooms} Bedrooms and {lodging.bathrooms} Bathrooms</Card.Text>
                <h3>Bedding</h3>
                <Card.Text>{getFullBeddingDisplayString(lodging.bedding)}</Card.Text>
              </>
              :
              <Card.Text>
                <span className='campground-span'>$300 monthly rate</span>
                <span className='campground-span'>$800 season rate (May 15 through September 30)</span>
              </Card.Text>
          }
          <Card.Text>{lodging.amenities && lodging.amenities.map(amenity => (
            <span className={'amenity-bubble'} key={`${lodging.id}-${amenity}`}>{amenity}</span>)
          )}</Card.Text>
          <Button onClick={() => openPhotoGalleryModal(lodging)} className='btn-primary'>Photo Gallery</Button>
          <Button onClick={() => openAvailabilityModal(lodging)} className='btn-primary' >Availability</Button>
        </Card.Body>
      </Card>

    </>
  )

}

export default LodgingFullDetails;