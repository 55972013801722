import {useSelector} from "react-redux";
import LodgingFullDetails from "./lodging-details/lodging-full-details";
import {useEffect, useState} from "react";
import './lodging-details/lodging.css'
import PhotoGallery from "./photo-gallery/photo-gallery";
import Availability from "./availability/availability";
import priceListPdf from './../../../static-imgs/condensedPriceList.pdf';

const Lodging = () => {
  const lodgings = useSelector(state => state.lodging.lodging);
  const [showPhotoGallery, setShowPhotoGallery] = useState(false);
  const [selectedLodging, setSelectedLodging] = useState(lodgings[0]);
  const [showAvailability, setShowAvailability] = useState(false);

  const openPhotoGalleryModal = (selectedLodging) => {
    setSelectedLodging(selectedLodging);
    setShowPhotoGallery(true);
  }
  const closePhotoGalleryModal = () => setShowPhotoGallery(false);

  const openAvailabilityModal = (selectedLodging) => {
    setSelectedLodging(selectedLodging);
    setShowAvailability(true);
  }
  const closeAvailabilityModal = () => setShowAvailability(false);

  useEffect(() => {
    setSelectedLodging(lodgings[0]);
  }, [lodgings])

  return (
    <>
      <a id='priceList' href={priceListPdf} target='_blank'>Condensed Price List</a>
      <div id={'lodging-cards'}>
        {lodgings.map(lodging => (
          <LodgingFullDetails key={lodging.id}
                              lodging={lodging}
                              openPhotoGalleryModal={openPhotoGalleryModal}
                              openAvailabilityModal={openAvailabilityModal} />
        ))}

      </div>
      <PhotoGallery
        lodgingImages={selectedLodging.images || []}
        lodgingName={selectedLodging.name || ''}
        showImages={showPhotoGallery}
        closePhotoGalleryModal={closePhotoGalleryModal}
      />
      <Availability
        lodgingId={selectedLodging.id}
        lodgingName={selectedLodging.name}
        lodgingType={selectedLodging.lodgingType}
        showAvailability={showAvailability}
        closeAvailabilityModal={closeAvailabilityModal}
      />
    </>
  )
}

export default Lodging;