import { configureStore} from "@reduxjs/toolkit";

import business from "./business";
import lodging from "./lodging";
import reservations from "./reservations";

const store = configureStore({
  reducer: {
    business, lodging, reservations
  }
});

export default store;
