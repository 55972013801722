import './reservationDirections.css'
import {Accordion} from "react-bootstrap";

const ReservationDirections = ({eventKey}) => {
  return (
    <Accordion.Item eventKey={eventKey} id='reservationDirections'>
      <Accordion.Header>Reservation Directions</Accordion.Header>
      <Accordion.Body>
        <ul>
          <li>🫎 Check-out time on Saturday’s 10:00AM. Check-in time anytime after 2:00PM.</li>
          <li>🫎 Bait for fishing and ice available in the barn office.</li>
          <li>🫎 In order to provide better service and cleaner cabins, we ask that you take care of your bill on or before Friday proceeding your departure.</li>
          <li>🫎 Please do not drive in front of cabins “ON WATER SIDE” from cabin #4-cabin #6.</li>
          <li>🫎 Please no smoking inside of Cabins or Apartment.</li>
          <li>🫎 Please leave your cabin in the manner you found it. No dirty dishes, floors swept, furniture back in place etc. WE GREATLY APPRECIATE IT!</li>
          <li>🫎 Our hours of service are between 8:00AM- 8:00PM.</li>
          <li>🫎 Please clean fish in fish cleaning shanty ONLY!</li>
          <li>🫎 Additional freezer space is available for those “take home” catches.</li>
          <li>🫎 We furnish only the initial roll of toilet paper and in cabin trash bags.</li>
          <li>🫎 All Dogs must be on a leash when outside of the cabin. All dog droppings are the responsibility of the owner to be removed as soon after the deposit as possible.</li>
          <li>🫎  All children 12 and under should be accompanied by an adult (over 21) when on the docks or the dock area.</li>
        </ul>
        <p>We hope you enjoy your stay! Feel free to contact us with any questions you may have!</p>
        <p>Aaron & Alicia</p>
        <p>906-297-5812</p>
      </Accordion.Body>
    </Accordion.Item>
  )
}
export default ReservationDirections;