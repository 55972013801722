import {actions} from "../actions";

const initial = {
  lodgingLoading: true,
  lodging: [],
}

const reducer = (state=initial, action) => {
  switch (action.type) {
    case actions.get_lodging:
      action.payload.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if(nameA < nameB) return -1;
        if(nameB > nameA) return 1;
        return 0;
      });
      state = {...state, lodging: action.payload, lodgingLoading: false}
      return state;
    default: return state;
  }
}

export default reducer;
