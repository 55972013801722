import Home from "./home/home";
import {Routes, Route} from "react-router-dom";
import CustomNav from "../nav";
import Lodging from "./lodging";
import Reservations from "./reservations";
import Policies from "./policies";
import Footer from "../footer";

const Body = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<CustomNav />}>
          <Route index element={<Home />}/>
          <Route path="lodging" element={<Lodging/>}  />
          <Route path="reservations" element={<Reservations/>}  />
          <Route path="policies" element={<Policies/>}  />
        </Route>
      </Routes>
      <Footer/>
    </>
  )
}
export default Body;