import './CampgroundRules.css'
import {ListGroup} from "react-bootstrap";

const CampgroundRules = () => {
  return (
    <section id="campgroundRules">
      <h2>Campground Rules</h2>
      <p>Campground management has taken all reasonable means to ensure that your stay here will be safe, pleasant and enjoyable.
        This property is privately owned and operated by  Aaron and Alicia Bright and is obligated to abide by all laws federal and local.
        Many of the following rules and regulations are for the safety and well being of our guests.
        Consideration and courtesy to others together with your cooperation in maintaining your lot will help sustain these standards.
      </p>

      <ListGroup id='listGroup'>
        <ListGroup.Item>⛺ Our camping season runs from May 15 - September 30th.
        </ListGroup.Item>
        <ListGroup.Item>⛺ Water and Electric will be turned off after September 30th or earlier depending on weather.
          It is your responsibility to winterize your own RV. Opening the campground may also be weather dependent.</ListGroup.Item>
        <ListGroup.Item>⛺ Please keep your camping lot clean and safe.</ListGroup.Item>
        <ListGroup.Item>⛺ One vehicle per camp lot. Please see Alicia or Aaron if you have temporary guests coming and we will direct you where to temporarily park.</ListGroup.Item>
        <ListGroup.Item>⛺ Your items must fit within your designated lot.
          If you exceed your lot and overflow onto another campsite you will be charged accordingly for everyday you exceed your lot space.</ListGroup.Item>
        <ListGroup.Item>⛺ Each Seasonal lot comes with a dock slip for one boat. Trailers are to be kept in the designated parking areas. See Aaron or Alicia with Questions.</ListGroup.Item>
        <ListGroup.Item>⛺ Up to four Occupants at each campsite. With additional occupants being charged five dollars a night.</ListGroup.Item>
        <ListGroup.Item>⛺ Quiet Hours are between 10pm and 8am. Please be respectful of your neighbors during this time frame.</ListGroup.Item>
        <ListGroup.Item>⛺ Children must be on your campsite after the 10pm curfew and fully supervised.
          All children 12 and under must be accompanied by an adult (21 over) when on the docks or near the area/shoreline.</ListGroup.Item>
        <ListGroup.Item>⛺ All pets must be on a leash and accompanied at all times. Please clean up after your pets' bathroom use.
          If you need a bag to do so please ask. We do not allow pets on the campground that are unfriendly or aggressive to people or other pets.
          Your pet should be quiet and not a disturbance to other campers.</ListGroup.Item>
        <ListGroup.Item>⛺ All Rvs having sewer drains must be sealed for the duration of their stay.
          Honeywell’s are to be used to properly dispose of sewage into the designated pump houses.</ListGroup.Item>
        <ListGroup.Item>⛺ Should you decide to vacate or sell your RV please notify Aaron or Alicia before doing so.
          Approval is required from us to transfer ownership to new occupants.</ListGroup.Item>
        <ListGroup.Item>⛺ We ask that you be respectful to all other guests. Anyone causing annoyances or disturbances to other campers will be asked to vacate the premises.</ListGroup.Item>
        <ListGroup.Item>⛺ Willfully causing harm or damage to others, their property, or the campground itself will not be tolerated.</ListGroup.Item>
      </ListGroup>
      <p>Please keep in mind that this campground is not only our home, but our livelihood.
        We appreciate everyone’s cooperation by being courteous to each other and following these rules.
      </p>
      <p>Thank you!!</p>
      <p>Aaron and Alicia</p>
    </section>
  )
}
export default CampgroundRules;